import React from 'react';
import Img from 'gatsby-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

const date = new Date();
const year = date.getFullYear();
const htSize = {
  maxWidth: '210px',
};
const r2Size = {
  maxWidth: '78.5px',
};

/**
 * Footer component
 * @component
 *
 * @returns {component}      - <Footer />
 */

const Footer = () => {
  const { hitrust, logo, r2, site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      r2: contentfulAsset(title: { eq: "R2-Assessment-Badge" }) {
        fluid(maxWidth: 110, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      hitrust: contentfulAsset(title: { eq: "HITRUST-white" }) {
        fluid(maxWidth: 300, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      logo: contentfulAsset(title: { eq: "logo-footer" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <footer
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyEnd,
        base.pa0,
        base.pl4,
        base.pr4,
        base.w100,
        theme.colorWhite,
        theme.shapeDark
      )}
    >
      <section
        className={cx(
          base.flex,
          base.itemsStart,
          base.justifyAround,
          base.ma4,
          base.pa4,
          base.w100,
          main.footerSection
        )}
      >
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.ma2
          )}
        >
          <Link to="/">
            <img
              src={logo.file.url}
              alt="Decision Point logo."
              className={main.logo}
            />
          </Link>
          <ul
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma0,
              base.pa0
            )}
          >
            <li
              className={cx(
                base.list,
                base.mb2,
                theme.colorBorderGrey,
                theme.copy1
              )}
            >
              2 Oliver Street
            </li>
            <li
              className={cx(
                base.list,
                base.mb2,
                theme.colorBorderGrey,
                theme.copy1
              )}
            >
              Boston, MA 02109
            </li>
            <li
              className={cx(
                base.list,
                base.mb2,
                theme.colorBorderGrey,
                theme.copy1
              )}
            >
              (617) 459-4550
            </li>
          </ul>
        </div>
        <div
          className={cx(
            base.flex,
            base.itemsStart,
            base.justifyBetween,
            base.ml4,
            base.mr4,
            base.w100,
            main.footerSectionInner
          )}
        >
          <div
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma2
            )}
          >
            <p className={cx(base.mb3, theme.title3, theme.colorWhite)}>
              Solutions
            </p>
            <nav
              aria-label="Primary footer navigation"
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsStart,
                base.justifyStart
              )}
            >
              <Link
                to="/solutions/retention/"
                data-testid="retention-link"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                CAHPS &amp; Member Experience
              </Link>
              <Link
                to="/solutions/hedis/"
                data-testid="hedis-link"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                HEDIS &amp; Adherence
              </Link>
              <Link
                to="/solutions/care-management/"
                data-testid="cm-link"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                Care Management
              </Link>
              <Link
                to="/solutions/provider-management/"
                data-testid="pm-link"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                Provider Management
              </Link>
            </nav>
          </div>
          <div
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.ma2
            )}
          >
            <p className={cx(base.mb3, theme.title3, theme.colorWhite)}>
              Company
            </p>
            <nav
              aria-label="Secondary footer navigation"
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsStart,
                base.justifyStart
              )}
            >
              <Link
                to="/why-us/"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                Why Us
              </Link>
              <Link
                to="/insights/"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                Insights
              </Link>
              <Link
                to="/about/"
                className={cx(
                  base.mb2,
                  main.footLink,
                  main.nu,
                  theme.colorBorderGrey,
                  theme.copy1
                )}
              >
                About Us
              </Link>
            </nav>
          </div>
        </div>
      </section>
      <div className={main.footDivider} />
      <section
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsEnd,
          base.justifyEnd,
          base.ma2,
          base.pl4,
          base.pr4,
          base.w100,
          main.footerSection
        )}
      >
        <div
          className={cx(
            base.flex,
            base.flexWrap,
            base.itemsCenter,
            base.justifyBetween,
            base.w100
          )}
        >
          <div
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.pb2,
              base.w100
            )}
            style={{ maxWidth: '50%', minWidth: '247px' }}
          >
            <p
              className={cx(base.ma0, base.pb1, theme.colorWhite, theme.copy2)}
              style={{ lineHeight: '1.5' }}
            >
              HEDIS® is a registered trademark of the National Committee for
              Quality Assurance (NCQA).
            </p>
            <p
              className={cx(base.ma0, base.pb1, theme.colorWhite, theme.copy2)}
              style={{ lineHeight: '1.5' }}
            >
              CAHPS® is a registered trademark of the Agency for Healthcare
              Research and Quality (AHRQ).
            </p>
          </div>
          <div
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyEnd,
              base.pb4,
              base.w100
            )}
            style={{ maxWidth: '50%', minWidth: '247px' }}
          >
            <Img
              fluid={r2.fluid}
              alt="R2 Assesment Based Certificate."
              style={{ ...r2Size, marginRight: '1rem' }}
              imgStyle={r2Size}
              className={cx(main.w100)}
            />
            <Img
              fluid={hitrust.fluid}
              alt="HITrust logo."
              style={htSize}
              imgStyle={htSize}
              className={cx(main.w100)}
            />
          </div>
        </div>
        <div
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyBetween,
            base.w100
          )}
        >
          <p
            className={cx(
              base.ma0,
              base.pb4,
              base.pt4,
              theme.colorTextLightGrey,
              theme.copy2
            )}
          >
            {/* Unicode 169 generates &copy; entity */}
            {`${String.fromCharCode(169)} ${year} ${
              site.siteMetadata.title
            } Inc.`}
          </p>
          <nav
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyBetween,
              base.pb4,
              base.pt4,
              base.w100,
              main.footNav
            )}
          >
            <Link
              to="/privacy/"
              className={cx(
                base.ma1,
                base.pa1,
                main.footLink,
                main.nu,
                theme.colorTextLightGrey,
                theme.copy2
              )}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms/"
              className={cx(
                base.ma1,
                base.pa1,
                main.footLink,
                main.nu,
                theme.colorTextLightGrey,
                theme.copy2
              )}
            >
              Terms of Use
            </Link>
            <Link
              to="/privacy-security/"
              className={cx(
                base.ma1,
                base.pa1,
                main.footLink,
                main.nu,
                theme.colorTextLightGrey,
                theme.copy2
              )}
            >
              Privacy &amp; Security
            </Link>
          </nav>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
