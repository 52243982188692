import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

const styles = {
  height: '70px',
  minHeight: '70px',
  minWidth: '70px',
  width: '70px',
};

/**
 * Solutions links component.
 * @component
 *
 * @param   {boolean} header  - Conditonal used for styling Solutions links
 * @returns {component}       - <Solutions header={boolean} />
 */

const SolutionsLinks = ({ header }) => {
  const { cm, hedis, retention, pm } = useStaticQuery(graphql`
    query {
      cm: contentfulAsset(title: { eq: "header-cm" }) {
        file {
          url
        }
      }
      hedis: contentfulAsset(title: { eq: "header-hedis" }) {
        file {
          url
        }
      }
      retention: contentfulAsset(title: { eq: "header-retention" }) {
        file {
          url
        }
      }
      pm: contentfulAsset(title: { eq: "header-pm" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <ul
      className={cx(
        base.flex,
        base.flexWrap,
        base.justifyCenter,
        base.ma0,
        base.pa0,
        base.w100,
        {
          [main.headerSolutionsItemList]: header,
        },
        {
          [main.homeSolutionsItemList]: !header,
        }
      )}
    >
      <li
        className={cx(base.flex, base.w100, {
          [main.headerSolutionsItem]: header,
          [main.solutionsItem]: !header,
        })}
      >
        <Link
          to="/solutions/memberexperience/"
          className={cx(base.flex, base.link, base.pa4, main.solutionsLink)}
        >
          <div className={cx(base.flex, base.pr3, main.solutionsIcon)}>
            <img
              src={retention.file.url}
              alt=""
              aria-hidden="true"
              style={styles}
            />
          </div>
          <div>
            <h3
              className={cx(base.pb1, {
                [main.solutionsLinkCopy]: header,
                [theme.colorDark]: !header,
                [theme.title2]: !header,
                [theme.title3Teal]: header,
              })}
            >
              CAHPS &amp; Member Experience
            </h3>
            <p
              className={cx(base.ma0, {
                [main.solutionsLinkSubCopy]: header,
                [main.stCopy]: !header,
                [theme.colorTextGrey]: !header,
                [theme.copy1Dark]: header,
              })}
            >
              {header
                ? 'Member satisfaction, powered by data.'
                : 'Member satisfaction and loyalty, powered by data. We provide the tools and strategy to retain and satisfy your membership.'}
            </p>
          </div>
        </Link>
      </li>
      <li
        className={cx(base.flex, base.w100, {
          [main.headerSolutionsItem]: header,
          [main.solutionsItem]: !header,
        })}
      >
        <Link
          to="/solutions/care-management/"
          className={cx(base.flex, base.link, base.pa4, main.solutionsLink)}
        >
          <div className={cx(base.flex, base.pr3, main.solutionsIcon)}>
            <img src={cm.file.url} alt="" aria-hidden="true" style={styles} />
          </div>
          <div>
            <h3
              className={cx(base.pb1, {
                [main.solutionsLinkCopy]: header,
                [theme.colorDark]: !header,
                [theme.title2]: !header,
                [theme.title3Violet]: header,
              })}
            >
              Care Management
            </h3>
            <p
              className={cx(base.ma0, {
                [main.solutionsLinkSubCopy]: header,
                [main.stCopy]: !header,
                [theme.colorTextGrey]: !header,
                [theme.copy1Dark]: header,
              })}
            >
              {header
                ? 'Healthier members, through data-driven decisions.'
                : 'Healthier members, through data-driven decisions. Decreased utilization and reduced admissions start with deep data insights.'}
            </p>
          </div>
        </Link>
      </li>
      <li
        className={cx(base.flex, base.w100, {
          [main.headerSolutionsItem]: header,
          [main.solutionsItem]: !header,
        })}
      >
        <Link
          to="/solutions/hedis/"
          className={cx(base.flex, base.link, base.pa4, main.solutionsLink)}
        >
          <div className={cx(base.flex, base.pr3, main.solutionsIcon)}>
            <img
              src={hedis.file.url}
              alt=""
              aria-hidden="true"
              style={styles}
            />
          </div>
          <div>
            <h3
              className={cx(base.pb1, {
                [main.solutionsLinkCopy]: header,
                [theme.colorDark]: !header,
                [theme.title2]: !header,
                [theme.title3Blue]: header,
              })}
            >
              HEDIS &amp; Adherence
            </h3>
            <p
              className={cx(base.ma0, {
                [main.solutionsLinkSubCopy]: header,
                [main.stCopy]: !header,
                [theme.colorTextGrey]: !header,
                [theme.copy1Dark]: header,
              })}
            >
              {header
                ? 'Fewer headaches, fewer open gaps.'
                : 'Fewer headaches, fewer open gaps. Prioritizing members and measures can be complex, but it doesn’t have to be.'}
            </p>
          </div>
        </Link>
      </li>
      <li
        className={cx(base.flex, base.w100, {
          [main.headerSolutionsItem]: header,
          [main.solutionsItem]: !header,
        })}
      >
        <Link
          to="/solutions/provider-management/"
          className={cx(base.flex, base.link, base.pa4, main.solutionsLink)}
        >
          <div className={cx(base.flex, base.pr3, main.solutionsIcon)}>
            <img src={pm.file.url} alt="" aria-hidden="true" style={styles} />
          </div>
          <div>
            <h3
              className={cx(base.pb1, {
                [main.solutionsLinkCopy]: header,
                [theme.colorDark]: !header,
                [theme.title2]: !header,
                [theme.title3Dark]: header,
              })}
            >
              Provider Management
            </h3>
            <p
              className={cx(base.ma0, {
                [main.solutionsLinkSubCopy]: header,
                [main.stCopy]: !header,
                [theme.colorTextGrey]: !header,
                [theme.copy1Dark]: header,
              })}
            >
              {header
                ? 'Informed referrals. Superior network sculpting.'
                : 'Informed referrals. Superior network sculpting. Deep insights that drive plan, provider, and member collaboration.'}
            </p>
          </div>
        </Link>
      </li>
    </ul>
  );
};

SolutionsLinks.propTypes = {
  header: PropTypes.bool.isRequired,
};

export default SolutionsLinks;
