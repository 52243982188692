import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import '../styles/critical.css';

/**
 * Meta Helmet component
 * @component
 *
 * @param   {object} location         - URL data
 * @param   {string} pageDescription  - Page specific description
 * @param   {string} pageTitle        - Page specific title
 * @param   {string} postPublishDate  - Page specific title
 * @returns {component}               - <Meta location={object} pageDescription={string} pageTitle={string} postPublishDate={string} />
 */

const Meta = ({
  location,
  pageDescription,
  pageTitle,
  postPublishDate,
  postImage,
}) => {
  const {
    site: {
      siteMetadata: { author, description, language, title },
    },
    icon,
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          author
          description
          language
          title
        }
      }
      icon: file(name: { eq: "icon" }) {
        publicURL
      }
    }
  `);

  // SEO friendly page title
  const dynamicTitle = pageTitle ? `${pageTitle} | ${title}` : title;
  const dynamicDesc = pageDescription || description;
  const baseURL = typeof location.origin !== 'undefined' ? location.origin : '';
  let schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: dynamicTitle,
    image: postImage ? postImage.url : `${baseURL}${icon.publicURL}`,
    url: `${typeof location.origin !== 'undefined' ? location.origin : '/'}`,
  };
  /* eslint-disable sort-keys */
  if (location.pathname.match(/insights\/[a-zA-Z0-9-_]+/) !== null) {
    schemaOrgJSONLD = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${baseURL}${location.pathname}`,
      },
      headline: pageTitle,
      image: postImage ? postImage.url : `${baseURL}${icon.publicURL}`,
      author: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${baseURL}${icon.publicURL}`,
        },
      },
      datePublished: postPublishDate,
      dateModified: postPublishDate,
    };
  }
  /* eslint-enable */

  const fontFace = `
    if ('fonts' in document) {
      const hkgR = new FontFace(
        'HK Grotesk',
        "url(/fonts/HKGrotesk-Regular.woff2) format('woff2'), url(/fonts/HKGrotesk-Regular.woff) format('woff')"
      );
      const hkgM = new FontFace(
        'HK Grotesk',
        "url(/fonts/HKGrotesk-Medium.woff2) format('woff2'), url(/fonts/HKGrotesk-Medium.woff) format('woff')",
        { weight: '500' }
      );
      const hkgSB = new FontFace(
        'HK Grotesk',
        "url(/fonts/HKGrotesk-SemiBold.woff2) format('woff2'), url(/fonts/HKGrotesk-SemiBold.woff) format('woff')",
        { weight: '700' }
      );
      const ppL = new FontFace(
        'Poppins',
        "url(/fonts/Poppins-Light.woff2) format('woff2'), url(/fonts/Poppins-Light.woff) format('woff')"
      );
      const ppM = new FontFace(
        'Poppins',
        "url(/fonts/Poppins-Medium.woff2) format('woff2'), url(/fonts/Poppins-Medium.woff) format('woff')",
        { weight: '500' }
      );
      Promise.all([
        hkgR.load(),
        hkgM.load(),
        hkgSB.load(),
        ppL.load(),
        ppM.load(),
      ]).then(fonts => {
        for (const font of fonts) {
          document.fonts.add(font);
        }
      });
    }
  `;

  return (
    <Helmet defaultTitle={dynamicTitle}>
      <html lang={language} />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1.0,initial-scale=1.0,maximum-scale=5.0,viewport-fit=cover"
      />
      <link rel="canonical" href={`${baseURL}${location.pathname}`} />
      <meta property="author" content={author} />
      <meta name="description" content={dynamicDesc} />
      <meta
        name="image"
        content={postImage ? postImage.url : `${baseURL}${icon.publicURL}`}
      />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <meta property="og:description" content={dynamicDesc} />
      <meta
        property="og:image"
        content={postImage ? postImage.url : `${baseURL}${icon.publicURL}`}
      />
      <meta
        property="og:image:type"
        content={`image/${postImage ? postImage.type : 'png'}`}
      />
      <meta
        property="og:image:width"
        content={postImage ? postImage.width : '512'}
      />
      <meta
        property="og:image:height"
        content={postImage ? postImage.height : '512'}
      />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={dynamicTitle} />
      <meta
        property="og:type"
        content={location.pathname.match(/posts\/.*\//) ? 'article' : 'website'}
      />
      <meta property="og:url" content={`${baseURL}${location.pathname}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={dynamicDesc} />
      <meta
        name="twitter:image"
        content={postImage ? postImage.url : `${baseURL}${icon.publicURL}`}
      />
      <meta name="twitter:title" content={dynamicTitle} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content={dynamicTitle} />
      <script data-func="font-loading" type="text/javascript">
        {fontFace}
      </script>
    </Helmet>
  );
};

Meta.propTypes = {
  location: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
  postPublishDate: PropTypes.string,
  postImage: PropTypes.shape({
    height: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.string,
  }),
};

Meta.defaultProps = {
  pageDescription: null,
  pageTitle: null,
  postPublishDate: null,
  postImage: null,
};

export default Meta;
