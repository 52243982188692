import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';

import Meta from './siteMeta';
import Header from './header';
import Footer from './footer';

/**
 * Layout component. Parent component for all others.
 * @component
 *
 * @param   {node}    children        - Child node elements
 * @param   {string}  location        - URL data object
 * @param   {string}  pageDescription - Page specific description; optional
 * @param   {string}  pageTitle       - Page specific title; optional
 * @param   {boolean} showcase        - Remove header and footer
 * @returns {component}               - <Layout children={node} location={string} pageDescription={string} pageTitle={string} />
 */

const Layout = ({
  children,
  location,
  pageDescription,
  pageTitle,
  postPublishDate,
  postImage,
  showcase,
}) => {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: contentfulBanners(
        contentful_id: { eq: "2Y6dsfvyq76MJQD6uFLkKK" }
      ) {
        active
        home
      }
    }
  `);

  // determine banner visiblity
  const bannerVisibility = banner.home ? location.pathname === '/' : true;

  return (
    <>
      <Meta
        pageDescription={pageDescription}
        location={location}
        pageTitle={pageTitle}
        postPublishDate={postPublishDate}
        postImage={postImage}
      />
      {!showcase ? <Header location={location} /> : null}
      <main
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyStart,
          main.headerLayoutPadding,
          {
            [main.headerBannerLayoutPadding]: banner.active && bannerVisibility,
          },
          main.mainRoot,
          {
            [main.mainRootShowcase]: showcase,
          }
        )}
        data-showcase={showcase}
      >
        {children}
      </main>
      {!showcase ? <Footer /> : null}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
  postPublishDate: PropTypes.string,
  postImage: PropTypes.shape({}),
  showcase: PropTypes.bool,
};

Layout.defaultProps = {
  pageDescription: null,
  pageTitle: null,
  postPublishDate: null,
  postImage: null,
  showcase: false,
};

export default Layout;
