import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import SolutionsLinks from './solutionsLinks';

/**
 * Header component
 * @component
 *
 * @param   {object} location - URL data
 * @returns {component}       - <Header location={object} />
 */

const Header = ({ location }) => {
  const { arrow, banner, logo } = useStaticQuery(graphql`
    query {
      arrow: contentfulAsset(title: { eq: "arrow" }) {
        file {
          url
        }
      }
      logo: contentfulAsset(title: { eq: "logo" }) {
        file {
          url
        }
      }
      banner: contentfulBanners(
        contentful_id: { eq: "2TonX7rqmFBg5CXjkqNUN6" }
      ) {
        active
        copy
        home
        link
      }
    }
  `);

  // determine banner visiblity
  const bannerVisibility = banner.home ? location.pathname === '/' : true;

  // determined if page has scrolled and if the view is on mobile
  const [state, setState] = useState({
    isScrolled: false,
    isVisible: false,
    mobileNav: false,
  });

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== state.isScrolled) {
        setState(prev => ({
          ...prev,
          isScrolled: !state.isScrolled,
        }));
      }
    };
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener('scroll', handleScroll);
    };
  }, [state.isScrolled]);

  // toggle visibility of Solutions dropdown
  const toggleVisibility = () => {
    setState(prev => ({
      ...prev,
      isVisible: !state.isVisible,
    }));
  };

  // toggle visibility of mobile navbar
  const toggleMobileNav = () => {
    setState(prev => ({
      ...prev,
      mobileNav: !state.mobileNav,
    }));
    if (state.isVisible) toggleVisibility();
  };

  return (
    <header
      className={cx(
        base.fixed,
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyBetween,
        base.top0,
        base.w100,
        base.z999,
        main.header,
        { [main.headerActive]: state.mobileNav },
        { [theme.shadow]: state.isScrolled },
        theme.shapeWhite
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexWrap,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.headerBar
        )}
      >
        <div
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyStart,
            base.mr1,
            main.brandWrap
          )}
        >
          <Link to="/" data-testid="home-link" className={main.logoLink}>
            <img
              src={logo.file.url}
              alt="Decision Point logo."
              className={main.logo}
            />
          </Link>
        </div>
        <nav
          aria-label="Primary header navigation"
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyBetween,
            base.ma0,
            base.overflowHidden,
            base.w100,
            main.headerNav,
            { [main.headerNavActive]: state.mobileNav }
          )}
        >
          <Link
            to="/why-us/"
            data-testid="why-us-link"
            className={cx(
              base.relative,
              base.tc,
              main.navLink,
              main.nu,
              theme.title3Dark
            )}
          >
            Why Us
          </Link>
          {/* Solutions Nav Toggle */}
          <button
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyBetween,
              base.ma0,
              base.relative,
              base.tc,
              main.navLink,
              main.nu,
              theme.title3Dark
            )}
            onClick={toggleVisibility}
            type="button"
          >
            Solutions
            <img
              src={arrow.file.url}
              alt=""
              aria-hidden="true"
              className={cx(base.dn, main.navLinkIcon, {
                [main.navLinkIconActive]: state.isVisible,
              })}
            />
          </button>
          {/* Mega Menu */}
          <div
            aria-hidden={!state.isVisible}
            className={cx(
              base.flex,
              base.flexWrap,
              base.justifyCenter,
              base.left0,
              base.overflowHidden,
              base.w100,
              base.z1000,
              main.headerSolutionsDropdown,
              { [main.headerSolutionsDropdownVisible]: state.isVisible },
              theme.shadow,
              theme.shapeWhite
            )}
          >
            <section
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.left0,
                base.ma0,
                base.pa0,
                base.w100,
                main.headerSolutionsCopyWrap
              )}
            >
              <div
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyCenter,
                  main.headerSolutionsText
                )}
              >
                <div className={base.tl}>
                  <h2 className={base.mb3}>Solutions</h2>
                  <p className={theme.title3Dark}>
                    Focused solutions for the modern health plan.
                  </p>
                </div>
              </div>
            </section>
            <section
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                base.pa0,
                base.w100,
                main.headerSolutionsLinks
              )}
            >
              <SolutionsLinks header />
            </section>
          </div>
          <Link
            to="/about/"
            data-testid="about-link"
            className={cx(
              base.relative,
              base.tc,
              main.navLink,
              { [main.navLinkMove]: state.isVisible },
              main.nu,
              theme.title3Dark
            )}
          >
            About
          </Link>
          <Link
            to="/insights/"
            data-testid="insights-link"
            className={cx(
              base.relative,
              base.tc,
              main.navLink,
              main.nu,
              theme.title3Dark
            )}
          >
            Insights
          </Link>
          <Link
            to="/contact/"
            data-testid="contact-us-link"
            className={cx(
              base.tc,
              main.navLinkButton,
              theme.dpBtnLg,
              theme.dpBtnBlue
            )}
          >
            Contact Us
          </Link>
        </nav>
      </div>
      {/* Mobile Nav Toggle */}
      <button
        className={cx(
          base.flexColumn,
          base.itemsCenter,
          base.justifyCenter,
          base.ma0,
          base.absolute,
          base.tc,
          base.w100,
          main.mobileNav,
          { [main.mobileNavActive]: state.mobileNav },
          main.nu,
          theme.title3Dark
        )}
        onClick={toggleMobileNav}
        type="button"
      >
        <span className={main.visuallyHidden}>Menu</span>
        <div
          className={cx(
            base.w100,
            main.mobileNavLine,
            main.mobileNavLineTop,
            theme.shapeDark
          )}
        />
        <div
          className={cx(
            base.w100,
            main.mobileNavLine,
            main.mobileNavLineMid,
            theme.shapeDark
          )}
        />
        <div
          className={cx(
            base.w100,
            main.mobileNavLine,
            main.mobileNavLineBottom,
            theme.shapeDark
          )}
        />
      </button>
      {banner.active && bannerVisibility && (
        <div
          className={cx(
            base.fixed,
            base.flex,
            base.flexColumn,
            base.itemsCenter,
            base.justifyBetween,
            base.w100,
            main.banner,
            { [main.bannerHidden]: state.isVisible || state.mobileNav },
            { [theme.shadow]: state.isScrolled },
            theme.shapeBlue
          )}
          role="presentation"
          aria-hidden={state.mobileNav}
        >
          <Link
            className={cx(
              base.pb1,
              base.pl2,
              base.pr2,
              base.pt1,
              base.tc,
              base.w100
            )}
            to={banner.link}
          >
            {banner.copy}
          </Link>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
